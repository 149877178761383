import { graphql } from "gatsby"

import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

import { useIsAuthenticated } from "@azure/msal-react"

import SEO, { getSeoProps } from "../components/Common/SEO"

import { RelatedSectionProps, RelatedSection } from "../components/Sections/RelatedSection"

import CommonCard from "../components/Cards/CommonCard"
import { HeroRight } from "../components/Hero/HeroImageHandlers"

import getBannerProps from "../components/Hero/getHeroBannerProps"

/**
 * @description
 * Også her er faktaboks relevant (huksk brødtekst over og under - dvs PLACEHOLDER for faktabox. MPXRender)
 * Relaterte prosjekter, inntil tre, sortert på nyeste først.
 * Merk at alle prosjekter (oversikt og detaljer) skal legges bak minside pålogging.
 * TODO: Bruke feltet PUBLIC fra Contentful til å bestemme om prosjekt er synlig eller ikke for åpne nettet
 * TODO: Legge til SEO Component
 * @param param0
 */

const ProjectTemplate = ({ location, data }) => {
  const isAuthenticated = useIsAuthenticated()

  const { contentfulProjects: content } = data

  let {
    allContentfulProjects: { nodes: projects },
  } = data

  if (projects !== null && projects !== undefined) {
    projects = projects.filter((x) => x.id !== content.id)

    if (projects.length >= 3) {
      const size = 3
      projects = projects.slice(0, size)
    }

    projects.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  }

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "article")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content)

  const relatedProps: RelatedSectionProps = {
    title: "Relaterte prosjkter",
    path: "nb/prosjekt/",
    data: projects,
  }

  return (
    <>
      <SEO {...helmetProps} />

      {isAuthenticated ? (
        <>
          <main>
            <article>
              <div className="bg-white overflow-hidden">
                <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
                  <div className="mx-auto text-base max-w-prose lg:max-w-none">
                    <h2 className="text-base text-indigo-600 font-semibold tracking-wide uppercase">{content.title}</h2>
                    <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      {content.subtitle}
                    </h3>
                  </div>
                  <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
                    <header>
                      <div className="relative lg:row-start-1 lg:col-start-2">
                        <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                          <HeroRight {...heroBannerProps} />
                        </div>
                      </div>
                    </header>
                    <div className="mt-8 lg:mt-0">
                      <div className="text-base max-w-prose mx-auto lg:max-w-none">
                        <p className="text-lg text-gray-500"></p>
                      </div>
                      <div className="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                        <p>
                          <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </main>
          <RelatedSection {...relatedProps} />

          <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
            Relaterte prosjkter
            <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
              {projects.map((thisProject) => {
                const props = {
                  data: thisProject,
                  url: "prosjekt",
                }
                return <CommonCard key={thisProject.id} {...props} />
              })}
            </div>
          </div>
        </>
      ) : (
        <div />
      )}
    </>
  )
}

export default ProjectTemplate

export const projectQuery = graphql`
  query projectsQuery($slug: String!) {
    contentfulProjects(slug: { eq: $slug }, node_locale: { eq: "nb-NO" }) {
      author {
        name
      }
      metadataDescription
      title
      subtitle
      slug
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      id
      node_locale
      contentful_id
      videoUrl
      public
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
      }
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
    }
    allContentfulProjects(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        slug
        id
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        title
        node_locale
        metadataDescription
        videoUrl
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
        }
        author {
          name
        }
        contentful_id
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
      }
    }
  }
`
